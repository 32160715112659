const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/install"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/a/invite"],
    exact: true,
    component: "Invite",
  },
  {
    path: ["/requestdeletion"],
    exact: true,
    component: "RequestAccountDeletion",
  },
];

export default routes;
